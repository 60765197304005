export const environment = {
    production: false,
    projectName: "I-COMM",
    dev: {
        REST_API_SERVER: "https://icomm.demo.api.testctsolution.it/api"
    },
    variables: {
        defaults: {
            supplier: 4
        }
    }
};
